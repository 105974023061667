import React from "react";
import {
  IResourceComponentsProps,
  useTranslate,
  useUpdate,
  useNavigation,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Input, DatePicker } from "antd";

export const MastersEdit: React.FC<IResourceComponentsProps> = () => {
  const { mutate } = useUpdate();
  const { formProps, saveButtonProps, queryResult } = useForm();
  const { goBack } = useNavigation();

  const data = queryResult?.data?.data;

  const onFinish = (value: any) => {
    if (data?.id) {
      mutate({
        resource: "masters",
        values: {
          master: {
            ...value,
          },
        },
        id: value.id,
      });
    }
  };

  return (
    <Edit saveButtonProps={saveButtonProps} title="แก้ไขแอดมิน">
      <Form {...formProps} layout="vertical" onFinish={onFinish}>
        <Form.Item label="ชื่อ" name={["id"]} hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label="ชื่อ"
          name={["fullname"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="เบอร์โทร"
          name={["phone"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="บัญชีเข้าใช้งาน"
          name={["username"]}
          rules={[
            {
              pattern: /^[a-zA-Z0-9_]{3,16}$/,
              message:
                "โปรดกรอกข้อความที่มีตัวอักษรภาษาอังกฤษ ตัวเลข หรือเครื่องหมายขีดกลาง (_) เท่านั้น และมีความยาวระหว่าง 3 ถึง 16 ตัวอักษร",
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="รหัสผ่าน"
          name={["password"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Edit>
  );
};
