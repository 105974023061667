import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

function SelfRegisterButton({ to }: { to: string }) {
  return (
    <Button type="primary" icon={<PlusCircleOutlined />}>
      <a href={to} target="_blank">
        ลงทะเบียนแทน
      </a>
    </Button>
  );
}

export default SelfRegisterButton;
