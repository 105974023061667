import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
  useParsed,
  useNavigation,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  DateField,
  CreateButton,
} from "@refinedev/antd";
import { Table, Space, Button } from "antd";
// import { getUserStatus } from "utils";

export const ListProduct: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { id } = useParsed();
  const { push } = useNavigation();

  const { tableProps } = useTable({
    resource: `${id}/products`,
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex={["updated_at"]}
          title="วันที่เพิ่มสินค้า"
          render={(value: any) => (
            <DateField value={value} format="D/MM/BBBB" />
          )}
        />
        {/* <Table.Column dataIndex="id" title="ไอดี" /> */}
        <Table.Column dataIndex="name" title="ชื่อสินค้า" />
        <Table.Column dataIndex="sku" title="SKU" />
        <Table.Column dataIndex="stock" title="สินค้าคงเหลือ" />
        <Table.Column
          dataIndex="price"
          title="ราคา"
          render={(text) => `฿${text}`}
        />
        <Table.Column
          title={translate("table.actions")}
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton
                hideText
                size="small"
                recordItemId={record.id}
                onClick={() => push(`show/${record.id}`)}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
