import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Rect,
  Svg,
} from "@react-pdf/renderer";
import { API_URL } from "config/axios";
import { GuideProps } from "types";

interface Props {
  data: GuideProps;
}

// Create Document Component
export const MyDocument = ({ data }: Props) => (
  <Document>
    <Page style={styles.body}>
      <View style={{ position: "absolute", right: 30, top: 30 }}>
        <Image
          style={{ width: 94, height: 122, objectFit: "cover" }}
          src={API_URL + data?.profile?.asset}
        />
      </View>
      <Text style={{ textAlign: "center", fontSize: 18 }}>
        LOCAL GUIDE APPLICATION FORM
      </Text>
      <Text style={{ textAlign: "center", fontSize: 18, padding: "5px 0" }}>
        ใบสมัครไกด์ท้องถิ่น
      </Text>
      <Text style={{ textAlign: "center", fontSize: 14 }}>
        กรอกข้อมูลด้วยตัวท่านเอง
      </Text>
      <Text style={{ textAlign: "center", fontSize: 9, paddingBottom: 20 }}>
        (To be completed in own handwriting)
      </Text>
      <Text style={{ fontSize: 14, paddingTop: 15 }}>ชื่อ : {data?.name}</Text>
      <Text style={{ fontSize: 14 }}>
        ชุมชนที่ลงทะเบียน : {data?.community?.name}
      </Text>
      <Text style={{ fontSize: 14, paddingTop: 15, fontWeight: "bold" }}>
        Personal information (ประวัติส่วนตัว)
      </Text>
      <Text style={{ fontSize: 14 }}>
        ที่อยู่ปัจจุบันเลขที่ {data?.address}
      </Text>
      <Text style={{ fontSize: 14, paddingTop: 15 }}>
        ประสบการณ์ {data?.experience}
      </Text>
      <Text style={{ fontSize: 14, paddingTop: 15 }}>
        โทรศัพท์ {data?.phone}
      </Text>
      <Text style={{ fontSize: 14 }}>เฟสบุ๊ค {data?.facebook}</Text>
      <Text style={{ fontSize: 14, paddingTop: 15 }} wrap={false}>
        ข้าพเจ้าขอรับรองว่า
        ข้อความดังกล่าวทั้งหมดในใบสมัครนี้เป็นความจริงทุกประการ
        หลังจากบริษัทตอบรับแล้วปรากฏว่า
        ข้อความในใบสมัครไกด์ท้องถิ่นเอกสารที่นำมาแสดง
        หรือรายละเอียดที่ให้ไว้ไม่เป็นความจริง
        บริษัทฯมีสิทธิ์ที่จะยกเลิกการเป็นไกด์ท้องถิ่นข้าพเจ้าได้โดยไม่ต้องจ่ายเงินชดเชยหรือค่าเสียหายใดๆ
        ทั้งสิ้น
      </Text>
      <View
        style={{
          textAlign: "center",
        }}
      >
        <Text style={{ fontSize: 14, paddingTop: 20 }}>……………………………………</Text>
        <Text style={{ fontSize: 14 }}>ลายมือชื่อผู้สมัคร</Text>
        <Text style={{ fontSize: 14 }}>(Applicants signature)</Text>
        <Text style={{ fontSize: 14 }}>………/………/………</Text>
      </View>
      <View style={{ paddingTop: 20 }}>
        <Text style={{ fontSize: 14, paddingBottom: 15 }}>การพิจารณา</Text>
        <View>
          <Svg viewBox="0 0 300 20">
            <Rect x="0" y="0" width="15" height="15" stroke="black" />
          </Svg>
          <Text
            style={{ position: "absolute", left: 40, top: 3, fontSize: 14 }}
          >
            ผ่าน
          </Text>
        </View>
        <View>
          <Svg viewBox="0 0 300 30">
            <Rect x="0" y="0" width="15" height="15" stroke="black" />
          </Svg>
          <Text
            style={{ position: "absolute", left: 40, top: 3, fontSize: 14 }}
          >
            ไม่ผ่าน
          </Text>
        </View>
      </View>
      <View style={styles.flex}>
        <View>
          <Text style={{ fontSize: 14, textAlign: "center" }}>
            ……………………………………
          </Text>
          <Text style={{ fontSize: 14, textAlign: "center" }}>
            ลายมือชื่อผู้สมัคร
          </Text>
          <Text style={{ fontSize: 14, textAlign: "center" }}>
            (Applicants signature)
          </Text>
          <Text style={{ fontSize: 14, textAlign: "center" }}>………/………/………</Text>
        </View>
        <View>
          <Text style={{ fontSize: 14, textAlign: "center" }}>
            ……………………………………
          </Text>
          <Text style={{ fontSize: 14, textAlign: "center" }}>
            ลายมือชื่อผู้สมัคร
          </Text>
          <Text style={{ fontSize: 14, textAlign: "center" }}>
            (Applicants signature)
          </Text>
          <Text style={{ fontSize: 14, textAlign: "center" }}>………/………/………</Text>
        </View>
      </View>
    </Page>
  </Document>
);

Font.register({
  family: "Sarabun",
  src: "/fonts/Sarabun.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Sarabun",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textAlign: "center",
    alignItems: "center",
    alignContent: "center",
  },
  center: {
    fontSize: 14,
    textAlign: "center",
  },
});
