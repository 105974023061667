import { ImageField } from "@refinedev/antd";
import { Space } from "antd";
import { API_URL } from "config/axios";
import { fallbackImage } from "utils";

function ImageList({ images }: { images: any }) {
  console.log(images);
  return (
    <Space style={{ paddingBottom: "1rem" }}>
      {!images || images.length === 0
        ? "ไม่มีรูปภาพ"
        : images?.map((img: any) => (
            <ImageField
              value={API_URL + img?.asset}
              width={80}
              fallback={fallbackImage}
            />
          ))}
    </Space>
  );
}

export default ImageList;
