import { Authenticated, GitHubBanner, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  ErrorComponent,
  notificationProvider,
  ThemedLayoutV2,
  ThemedSiderV2,
  ThemedTitleV2,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";

import { UsersList, UsersCreate, UsersEdit, UsersShow } from "pages/users";

import { ForgotPassword } from "pages/forgotPassword";
import { Login } from "pages/login";
import { Register } from "pages/register";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { authProvider } from "./authProvider";
import { Header } from "./components/header";
import { ColorModeContextProvider } from "./contexts/color-mode";
import axiosInstance, { API_URL } from "config/axios";

import { EventsCreate, EventsEdit, EventsList, EventsShow } from "pages/events";
import {
  ApartmentOutlined,
  AuditOutlined,
  CompassOutlined,
  DashboardOutlined,
  FlagOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { DashboardPage } from "pages/dashboard";
import { CustomerEdit, CustomerList, CustomerShow } from "pages/customers";
import AppIcon from "components/AppIcon";
import {
  MastersCreate,
  MastersEdit,
  MastersList,
  MastersShow,
} from "pages/masters";
import { PartnerList, PartnerShow } from "pages/partners";
import { PartnerEdit } from "pages/partners/edit";
import { PackageList, ShowPackage } from "pages/partners/tour/package";
import { BookingList, ShowBooking } from "pages/partners/tour/booking";
import { ListProduct } from "pages/partners/shop/products";
import { ShowProduct } from "pages/partners/shop/products/show";
import { ListOrder, ShowOrder } from "pages/partners/shop/orders";
import { LocalGuideList, LocalGuideShow } from "pages/guides";
import { LocalGuideEdit } from "pages/guides/edit";
import {
  OrganizationsEdit,
  OrganizationsList,
  OrganizationsShow,
} from "pages/organizations";

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            dataProvider={dataProvider(API_URL, axiosInstance)}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            resources={[
              {
                name: "dashboard",
                list: "/",
                meta: {
                  label: "แดชบอร์ด",
                  icon: <DashboardOutlined />,
                },
              },
              {
                name: "organizations",
                list: "/organizations",
                show: "/organizations/show/:id",
                edit: "/organizations/edit/:id",
                meta: {
                  label: "องค์กรจัดการท่องเที่ยว",
                  icon: <FlagOutlined />,
                },
              },
              {
                name: `business_partners`,
                meta: {
                  label: "ผู้ประกอบการ",
                  icon: <ApartmentOutlined />,
                },
              },
              {
                name: `TourActivity`,
                list: `/business_partners/tour`,
                show: `/business_partners/tour/show/:id`,
                edit: `/business_partners/tour/edit/:id`,
                meta: {
                  label: "ทัวร์",
                  icon: <ApartmentOutlined />,
                  parent: "business_partners",
                },
              },
              {
                name: `Restaurant`,
                list: `/business_partners/restaurant`,
                show: `/business_partners/restaurant/show/:id`,
                edit: `/business_partners/restaurant/edit/:id`,
                meta: {
                  label: "ร้านอาหาร",
                  icon: <ApartmentOutlined />,
                  parent: "business_partners",
                },
              },
              {
                name: `Hotel`,
                list: `/business_partners/hotel`,
                show: `/business_partners/hotel/show/:id`,
                edit: `/business_partners/hotel/edit/:id`,
                meta: {
                  label: "โรงแรม",
                  icon: <ApartmentOutlined />,
                  parent: "business_partners",
                },
              },
              {
                name: `Shop`,
                list: `/business_partners/shop`,
                show: `/business_partners/shop/show/:id`,
                edit: `/business_partners/shop/edit/:id`,
                meta: {
                  label: "ร้านค้า",
                  icon: <ApartmentOutlined />,
                  parent: "business_partners",
                },
              },
              {
                name: `Other`,
                list: `/business_partners/other`,
                show: `/business_partners/other/show/:id`,
                edit: `/business_partners/other/edit/:id`,
                meta: {
                  label: "อื่นๆ",
                  icon: <ApartmentOutlined />,
                  parent: "business_partners",
                },
              },
              {
                name: `local_guides`,
                list: `/local_guides`,
                show: `/local_guides/show/:id`,
                edit: `/local_guides/edit/:id`,
                meta: {
                  label: "ไกด์ท้องถิ่น",
                  icon: <FlagOutlined />,
                },
              },
              {
                name: "users-parent",
                meta: {
                  label: "ข้อมูลผู้ใช้",
                  icon: <TeamOutlined />,
                },
              },
              {
                name: "users?type=Organization",
                list: "/users/organizations",
                show: "/users/organizations/show/:id",
                edit: "/users/organizations/edit/:id",
                meta: {
                  label: "องค์กร",
                  parent: "users-parent",
                  icon: <ApartmentOutlined />,
                },
              },
              {
                name: "users?type=BusinessPartner",
                list: "/partners",
                show: "/partners/show/:id",
                edit: "/partners/edit/:id",
                meta: {
                  label: "ผู้ประกอบการ",
                  parent: "users-parent",
                  icon: <AuditOutlined />,
                },
              },
              {
                name: "users?type=LocalGuide",
                list: "/localguide",
                show: "/localguide/show/:id",
                edit: "/localguide/edit/:id",
                meta: {
                  label: "ไกด์ท้องถิ่น",
                  parent: "users-parent",
                  icon: <CompassOutlined />,
                },
              },
              {
                name: "customers",
                list: "/customers",
                show: "/customers/show/:id",
                edit: "/customers/edit/:id",
                meta: {
                  label: "นักท่องเที่ยว",
                  parent: "users-parent",
                  icon: <FlagOutlined />,
                },
              },
              {
                name: "tani_events",
                list: "/events",
                create: "/events/create",
                edit: "/events/edit/:id",
                show: "/events/show/:id",
                meta: {
                  label: "กิจกรรม",
                },
              },
              {
                name: "masters",
                list: "/masters",
                create: "/masters/create",
                edit: "/masters/edit/:id",
                show: "/masters/show/:id",
                meta: {
                  label: "แอดมิน",
                  icon: <UserOutlined />,
                },
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              projectId: "01GeEk-9pu56y-qevuHx",
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                    <ThemedLayoutV2
                      Header={() => <Header sticky />}
                      Title={({ collapsed }) =>
                        collapsed ? <span>A</span> : <AppIcon />
                      }
                    >
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route index element={<DashboardPage />} />

                <Route path="/organizations">
                  <Route index element={<OrganizationsList />} />
                  <Route path="show/:id" element={<OrganizationsShow />} />
                  <Route path="edit/:id" element={<OrganizationsEdit />} />
                </Route>

                <Route path="/business_partners/tour">
                  <Route index element={<PartnerList />} />
                  <Route path="show/:id" element={<PartnerShow />} />
                  <Route path="edit/:id" element={<PartnerEdit />} />
                  <Route path="packages/:id" element={<PackageList />} />
                  <Route
                    path="packages/:tour_id/show/:id"
                    element={<ShowPackage />}
                  />
                  <Route path="booking/:id" element={<BookingList />} />
                  <Route
                    path="booking/:tour_id/show/:id"
                    element={<ShowBooking />}
                  />
                </Route>

                <Route path="/business_partners/restaurant">
                  <Route index element={<PartnerList />} />
                  <Route path="show/:id" element={<PartnerShow />} />
                  <Route path="edit/:id" element={<PartnerEdit />} />
                </Route>

                <Route path="/business_partners/hotel">
                  <Route index element={<PartnerList />} />
                  <Route path="show/:id" element={<PartnerShow />} />
                  <Route path="edit/:id" element={<PartnerEdit />} />
                </Route>

                <Route path="/business_partners/shop">
                  <Route index element={<PartnerList />} />
                  <Route path="show/:id" element={<PartnerShow />} />
                  <Route path="products/:id" element={<ListProduct />} />
                  <Route path="edit/:id" element={<PartnerEdit />} />s
                  <Route
                    path="products/:shop_id/show/:id"
                    element={<ShowProduct />}
                  />
                  <Route path="orders/:id" element={<ListOrder />} />
                  <Route
                    path="orders/:shop_id/show/:id"
                    element={<ShowOrder />}
                  />
                </Route>
                <Route path="/business_partners/other">
                  <Route index element={<PartnerList />} />
                  <Route path="show/:id" element={<PartnerShow />} />
                  <Route path="edit/:id" element={<PartnerEdit />} />
                </Route>

                <Route path="/users">
                  <Route index element={<UsersList />} />
                  <Route path="edit/:id" element={<UsersEdit />} />
                  <Route path="show/:id" element={<UsersShow />} />
                </Route>
                <Route path="/users/organizations">
                  <Route index element={<UsersList />} />
                  <Route path="edit/:id" element={<UsersEdit />} />
                  <Route path="show/:id" element={<UsersShow />} />
                </Route>
                <Route path="/partners">
                  <Route index element={<UsersList />} />
                  <Route path="edit/:id" element={<UsersEdit />} />
                  <Route path="show/:id" element={<UsersShow />} />
                </Route>
                <Route path="/local_guides">
                  <Route index element={<LocalGuideList />} />
                  <Route path="show/:id" element={<LocalGuideShow />} />
                  <Route path="edit/:id" element={<LocalGuideEdit />} />
                </Route>
                <Route path="/localguide">
                  <Route index element={<UsersList />} />
                  <Route path="edit/:id" element={<UsersEdit />} />
                  <Route path="show/:id" element={<UsersShow />} />
                </Route>
                <Route path="/customers">
                  <Route index element={<CustomerList />} />
                  <Route path="show/:id" element={<CustomerShow />} />
                  <Route path="edit/:id" element={<CustomerEdit />} />
                </Route>
                <Route path="/events">
                  <Route index element={<EventsList />} />
                  <Route path="create" element={<EventsCreate />} />
                  <Route path="edit/:id" element={<EventsEdit />} />
                  <Route path="show/:id" element={<EventsShow />} />
                </Route>
                <Route path="/masters">
                  <Route index element={<MastersList />} />
                  <Route path="create" element={<MastersCreate />} />
                  <Route path="edit/:id" element={<MastersEdit />} />
                  <Route path="show/:id" element={<MastersShow />} />
                </Route>
                <Route path="*" element={<ErrorComponent />} />
              </Route>

              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
              </Route>
            </Routes>

            <RefineKbar />
            <UnsavedChangesNotifier />
            <DocumentTitleHandler />
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
