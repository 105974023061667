import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, DatePicker } from "antd";
import dayjs from "dayjs";

export const MastersCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps} title="สร้างแอดมิน">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="ชื่อ"
          name={["master", "fullname"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="เบอร์โทร"
          name={["master", "phone"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="บัญชีเข้าใช้งาน"
          name={["master", "username"]}
          rules={[
            {
              pattern: /^[a-zA-Z0-9_]{3,16}$/,
              message:
                "โปรดกรอกข้อความที่มีตัวอักษรภาษาอังกฤษ ตัวเลข หรือเครื่องหมายขีดกลาง (_) เท่านั้น และมีความยาวระหว่าง 3 ถึง 16 ตัวอักษร",
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="รหัสผ่าน"
          name={["master", "password"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Create>
  );
};
