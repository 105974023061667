import React from "react";

export default function AppLogo({ text }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img width={60} src="/logo.svg" alt="" />
      <span style={{ marginTop: 12, fontSize: 16 }}>{text}</span>
    </div>
  );
}
