import React, { useState } from "react";

import {
  Card,
  Row,
  Col,
  Space,
  Avatar,
  Rate,
  Image,
  Button,
  Input,
} from "antd";

import UploadMultipleStandAlone from "components/UploadMultipleStandAlone";

import { useParsed, useList, useCustomMutation } from "@refinedev/core";
import { ReplyReviewProps, ReviewProps, ImageProps } from "types";
import { API_URL } from "config/axios";
import { date } from "utils";

interface ReplyReviewProps2 {
  data: ReplyReviewProps;
  onDelete: () => void;
  onEdit: () => void;
}

const ReplyList = ({ data, onDelete, onEdit }: ReplyReviewProps2) => {
  return (
    <>
      <div
        style={{
          color: "rgba(0, 0, 0, 0.45)",
          fontStyle: "italic",
          display: "flex",
          flexDirection: "column",
          paddingTop: 15,
          paddingBottom: 15,
          paddingLeft: 30,
        }}
      >
        <span style={{ paddingBottom: 10 }}>{data.text}</span>
        {data.images && (
          <Image.PreviewGroup
            preview={{
              onChange: (current, prev) =>
                console.log(`current index: ${current}, prev index: ${prev}`),
            }}
          >
            <Space>
              {data.images.map((img) => (
                <div
                  style={{
                    width: 80,
                    objectFit: "contain",
                    objectPosition: "center",
                  }}
                >
                  <Image src={API_URL + img.asset} />
                </div>
              ))}
            </Space>
          </Image.PreviewGroup>
        )}
      </div>
      <div style={{ paddingLeft: 15 }}>
        <Button type="link" onClick={onEdit}>
          แก้ไข
        </Button>
        <Button type="link" onClick={onDelete}>
          ลบ
        </Button>
      </div>
    </>
  );
};

const ReviewPage = ({ resource }: { resource: string }) => {
  const [reply, setReply] = useState<number | null>(null);
  const [edit, setEdit] = useState<number | null>(null);
  const [replyText, setReplyText] = useState("");
  const [replyImages, setReplyImages] = useState<ImageProps[]>([]);

  const { id } = useParsed();

  const { data, refetch, isLoading } = useList<ReviewProps>({
    resource: `/${resource}/${id}/reviews`,
  });

  const { mutate } = useCustomMutation();

  const onDelete = (review_id: number, reply_id: number) => {
    mutate(
      {
        url: `/${review_id}/reply_reviews/${reply_id}`,
        method: "delete",
        values: {},
      },
      {
        onSuccess: (data, variables, context) => {
          refetch();
          setReply(null);
        },
      }
    );
  };

  const handleReply = (id: number) => {
    const user_id = localStorage.getItem("user_id") || "";
    const payload = {
      reply_review: {
        text: replyText ? replyText : null,
        images: replyImages.length > 0 ? replyImages : null,
        user_id: parseInt(user_id),
      },
    };

    mutate(
      {
        url: `/${id}/reply_reviews`,
        method: "post",
        values: payload,
      },
      {
        onSuccess: (data, variables, context) => {
          refetch();
          setReply(null);
        },
      }
    );
  };

  const editReply = (id: number) => {
    const payload = {
      reply_review: {
        text: replyText,
        images: replyImages,
      },
    };
    mutate(
      {
        url: `/${id}/reply_reviews/${edit}`,
        method: "put",
        values: payload,
      },
      {
        onSuccess: (data, variables, context) => {
          refetch();
          setReply(null);
          setEdit(null);
          setReplyText("");
          setReplyImages([]);
        },
      }
    );
  };

  const updateHideStatus = (review_id: number, status: boolean) => {
    mutate(
      {
        url: `/${resource}/${id}/reviews/${review_id}`,
        method: "put",
        values: {
          review: { is_shown: !status },
        },
      },
      {
        onSuccess: (data, variables, context) => {
          refetch();
        },
      }
    );
  };

  return (
    <Card title="ความคิดเห็น" style={{ marginTop: 15 }} loading={isLoading}>
      {data?.data.length === 0 && "ยังไม่มีรีวิว"}
      {data?.data.map((item) => (
        <>
          <Row style={{ paddingBottom: 16 }}>
            <Col span={1}>
              <Avatar
                style={{ marginTop: 8 }}
                src={API_URL + item.customer?.profile?.asset}
              />
            </Col>
            <Col span={21}>
              <Space direction="vertical" style={{ gap: 0 }}>
                <Space>
                  <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                    {`${item.customer.first_name} ${item.customer.last_name}`}
                  </span>
                  <span>{date.thaiFormatWithTime(item.created_at)}</span>
                </Space>
                <Rate disabled defaultValue={item.star} />
                <p>{item.text}</p>
                <Image.PreviewGroup
                  preview={{
                    onChange: (current, prev) =>
                      console.log(
                        `current index: ${current}, prev index: ${prev}`
                      ),
                  }}
                >
                  <Space>
                    {item.images?.map((img) => (
                      <Image width={80} src={API_URL + img.asset} />
                    ))}
                  </Space>
                </Image.PreviewGroup>
              </Space>
              {item.reply.map(
                (reply) =>
                  edit !== reply.id && (
                    <ReplyList
                      data={reply}
                      onDelete={() => onDelete(item.id, reply.id)}
                      onEdit={() => {
                        setEdit(reply.id);
                        setReply(item.id);
                        setReplyText(reply.text);
                        setReplyImages(reply.images);
                      }}
                    />
                  )
              )}
              {item.id === reply ? (
                <div style={{ paddingTop: 16 }}>
                  <Space direction="vertical">
                    <Input
                      width={300}
                      placeholder="เพิ่มความคิดเห็น"
                      onChange={(e) => setReplyText(e.target.value)}
                      value={replyText}
                    />
                    <UploadMultipleStandAlone
                      name="images"
                      label=""
                      onChange={(e) => {
                        const res = e.map((item: any) => item.response);
                        if (res.length > 0) {
                          setReplyImages(res);
                        }
                      }}
                      value={replyImages}
                    />
                    <Button
                      style={{ position: "relative", top: -10 }}
                      type="primary"
                      onClick={() => {
                        edit ? editReply(item.id) : handleReply(item.id);
                      }}
                    >
                      บันทึก
                    </Button>
                  </Space>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      color: "rgba(0, 0, 0, 0.45)",
                      fontStyle: "italic",
                      paddingTop: 30,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setReply(item.id);
                      setReplyText("");
                      setReplyImages([]);
                    }}
                  >
                    <span>ตอบกลับ...</span>
                  </div>
                </>
              )}
            </Col>
            <Col span={2}>
              <Button
                type="link"
                onClick={() => updateHideStatus(item.id, item.is_shown)}
              >
                {item.is_shown ? "ซ่อน" : "เลิกซ่อน"}
              </Button>
            </Col>
          </Row>
          <hr style={{ border: "1px solid #f0f0f0" }} />
        </>
      ))}
    </Card>
  );
};

export default ReviewPage;
