import { useList, useNavigation } from "@refinedev/core";
import { Card, Statistic } from "antd";

type Type =
  | "destination_visit"
  | "community"
  | "local_guide"
  | "business_partner";

export const DashboardTotalCountCard: React.FC<{
  resource: Type;
  label: string;
  to?: string;
}> = ({ resource, label, to }) => {
  const { push } = useNavigation();

  const { data: count } = useList({
    resource: `/analytics/counter`,
    queryOptions: {
      select: (data: any) => data.data[`${resource}_count`],
    },
  });

  return (
    <Card
      bordered={false}
      onClick={() => to && push(to)}
      style={{ cursor: to ? "pointer" : "default" }}
    >
      <Statistic
        title={label}
        value={(count as number | undefined) ?? 0}
        valueStyle={{ color: "#56ABD4", fontSize: 64, fontWeight: "bold" }}
      />
    </Card>
  );
};
