import React, { useState, useEffect } from "react";
import {
  IResourceComponentsProps,
  useForm,
  useShow,
  useNavigation,
  useParsed,
} from "@refinedev/core";
import { Show, DateField, ImageField } from "@refinedev/antd";
import { Button, Descriptions, Popconfirm, Select, Space, Image } from "antd";
import axiosInstance, { API_URL } from "config/axios";
import { NoImage } from "utils";

import { ImageProps } from "types";
import ReviewPage from "components/ReviewPage";

const ORG_ID = localStorage.getItem("org_id");

export const ShowPackage: React.FC<IResourceComponentsProps> = () => {
  const { goBack } = useNavigation();
  const { id, params } = useParsed();
  const { queryResult } = useShow({
    resource: `${params?.tour_id}/packages`,
    id,
  });

  const [status, setStatus] = useState("");

  const { data, isLoading } = queryResult;

  const record = data?.data;

  const fileRegex = /\/([^/]+)$/;

  return (
    <>
      <Show isLoading={isLoading}>
        <Descriptions title="ข้อมูลแพ็คเกจ" layout="vertical">
          <Descriptions.Item label="ชื่อแพคเกจ">
            {record?.name}
          </Descriptions.Item>
          <Descriptions.Item label="ประเภท">
            {record?.types.join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="รูปแบบ">{record?.period}</Descriptions.Item>
          <Descriptions.Item label="สถานที่ท่องเที่ยว">
            {record?.destination_visit.name}
          </Descriptions.Item>
          <Descriptions.Item label="ไกด์ท้องถิ่น">
            {record?.local_guide.name}
          </Descriptions.Item>
          <Descriptions.Item label="คำบรรยายสั้นๆ">
            {record?.desciption}
          </Descriptions.Item>
          <Descriptions.Item label="รูปภาพ" span={3}>
            <Image.PreviewGroup
              preview={{
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Space>
                {record?.images.map((img: ImageProps) => (
                  <Image width={80} src={API_URL + img.asset} />
                ))}
              </Space>
            </Image.PreviewGroup>
          </Descriptions.Item>
          <Descriptions.Item label="ราคา/ท่าน (บาท)" span={3}>
            {record?.price}
          </Descriptions.Item>
          <Descriptions.Item label="รายละเอียด" span={3}>
            <div dangerouslySetInnerHTML={{ __html: record?.content }} />
          </Descriptions.Item>
          <Descriptions.Item label="เงื่อนไข">
            <div dangerouslySetInnerHTML={{ __html: record?.conditions }} />
          </Descriptions.Item>
        </Descriptions>
      </Show>
      <ReviewPage resource="packages" />
    </>
  );
};
