import React from "react";
import JoditEditor from "jodit-react";

interface OnChangeHandler {
  (e: any): void;
}

type Props = {
  value: string;
  placeholder: string;
  onChange: OnChangeHandler;
};

const TextEditor: React.FC<Props> = ({ value, onChange, placeholder }) => {
  const config = {
    toolbar: true,
    uploader: {
      insertImageAsBase64URI: true,
    },
    height: 500,
  };

  return (
    <>
      <JoditEditor config={config} value={value} onBlur={onChange} />
    </>
  );
};

export default TextEditor;
