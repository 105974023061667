import React from "react";
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Show, TextField, DateField, ImageField } from "@refinedev/antd";
import { Space, Typography } from "antd";

import { API_URL } from "config/axios";

const { Title } = Typography;

interface ImageProps {
  id: number;
  asset: string;
}

export const EventsShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>รูปภาพ</Title>
      <Space>
        {record?.banners?.map(({ asset, id }: ImageProps) => (
          <ImageField key={id} value={API_URL + asset} width={100} />
        ))}
      </Space>
      <Title level={5} style={{ paddingTop: 30 }}>
        ชื่อกิจกรรม
      </Title>
      <TextField value={record?.name} />
      <Title level={5} style={{ paddingTop: 30 }}>
        รายละเอียด
      </Title>
      <div dangerouslySetInnerHTML={{ __html: record?.content }} />
      <Title level={5}>ที่อยู่</Title>
      <TextField value={record?.address} />
      <Title level={5}>วันที่จัดกิจกรรม</Title>
      <DateField value={record?.start_date} />
      {" - "}
      <DateField value={record?.end_date} />
      <Title level={5}>แผนที่</Title>
      <div
        style={{
          borderRadius: 6,
          width: 600,
          height: 400,
          overflow: "hidden",
        }}
        dangerouslySetInnerHTML={{ __html: record?.embed_map }}
      />
    </Show>
  );
};
