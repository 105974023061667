import React from "react";
import {
  IResourceComponentsProps,
  useNavigation,
  useParsed,
} from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Row, Col, Select, Space, Typography } from "antd";
import UploadOne from "components/UploadOne";
import UploadFile from "components/UploadFile";
import UploadMultiple from "components/UploadMultiple";
import TextEditor from "components/TextEditor";

const { TextArea } = Input;

export const PartnerEdit: React.FC<IResourceComponentsProps> = () => {
  const { id, resource } = useParsed();
  const { goBack } = useNavigation();

  const { formProps, saveButtonProps, queryResult, onFinish } = useForm({
    resource: "business_partners",
    id,
    onMutationSuccess: (data, variables, context) => {
      goBack();
    },
  });

  const data = queryResult?.data?.data;

  const handleEdit = async (values: any) => {
    let payload = {
      business_partner: {
        ...values,
      },
    };
    await onFinish(payload);
  };

  return (
    <>
      <Edit
        saveButtonProps={saveButtonProps}
        headerButtons={false}
        title={`แก้ไข${resource?.meta?.label}`}
      >
        <Form {...formProps} layout="vertical" onFinish={handleEdit}>
          <Row gutter={12}>
            <Col span={24}>
              <div style={{ width: 150 }}>
                <UploadOne label="โลโก้" name="logo" value={data?.logo} />
              </div>
            </Col>
            <Col span={12}>
              <Form.Item
                name="name"
                label="ชื่อธุรกิจ"
                rules={[{ required: true }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="address"
                label="ที่อยู่"
                rules={[{ required: false }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="lat"
                label={
                  <Space>
                    ละติจูด
                    <Typography.Link
                      target="_blank"
                      href="https://drive.google.com/file/d/1TmkDU_0kIA-0iQqMUb1iv3MhwmcoV-DZ/view"
                    >
                      ดูวิธีการ
                    </Typography.Link>
                  </Space>
                }
                rules={[{ required: false }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="long"
                label={
                  <Space>
                    ลองติจูด
                    <Typography.Link
                      target="_blank"
                      href="https://drive.google.com/file/d/1TmkDU_0kIA-0iQqMUb1iv3MhwmcoV-DZ/view"
                    >
                      ดูวิธีการ
                    </Typography.Link>
                  </Space>
                }
                rules={[{ required: false }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="embed_map"
                label={
                  <Space>
                    Embed a map
                    <Typography.Link
                      target="_blank"
                      href="https://drive.google.com/file/d/1pTh9pn4q5qxPWD5Ko72U-0HtMf3UgIYa/view"
                    >
                      ดูวิธีการ
                    </Typography.Link>
                  </Space>
                }
                rules={[{ required: false }]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="detail"
                label="คำบรรยายสั้นๆ "
                rules={[{ required: false }]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <UploadMultiple
                name={["images"]}
                label="รูปภาพ"
                value={data?.images}
              />
            </Col>
            <Col span={24}>
              <Form.Item
                name="content"
                label="รายละเอียด"
                rules={[{ required: false }]}
              >
                {/* @ts-ignore */}
                <TextEditor />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="facebook"
                label="เฟสบุ๊ค"
                rules={[{ required: false }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phone"
                label="เบอร์โทร"
                rules={[{ required: false }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="id_card_pdf"
                label="สำเนาบัตรประชาชน"
                rules={[{ required: false }]}
                getValueFromEvent={(e: any) => e.file.response}
              >
                <UploadFile />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="house_registration_pdf"
                label="สำเนาทะเบียนบ้าน "
                rules={[{ required: false }]}
                getValueFromEvent={(e: any) => e.file.response}
              >
                <UploadFile />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="commercial_registration_pdf"
                label="เอกสารการจดทะเบียนร้านค้า/พาณิชย์"
                rules={[{ required: false }]}
                getValueFromEvent={(e: any) => e.file.response}
              >
                <UploadFile />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Edit>
    </>
  );
};
