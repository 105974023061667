import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DateField,
  DeleteButton,
} from "@refinedev/antd";
import { Table, Space } from "antd";

export const EventsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="รหัส" />
        <Table.Column dataIndex="name" title="หัวข้อกิจกรรม" />
        <Table.Column dataIndex="address" title="ที่อยู่" />
        <Table.Column
          dataIndex={["start_date", "end_date"]}
          title="วันที่จัดกิจกรรม"
          render={(value, data: any) => {
            return (
              <>
                <DateField value={data?.start_date} />
                {" - "}
                <DateField value={data?.end_date} />
              </>
            );
          }}
        />
        <Table.Column
          title={translate("table.actions")}
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
                onSuccess={() => {
                  console.log("Delete Success");
                }}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
