import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
  useParsed,
  useNavigation,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  DateField,
} from "@refinedev/antd";
import { Table, Space } from "antd";

export const ListOrder: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParsed();
  const { push } = useNavigation();
  const translate = useTranslate();
  const { tableProps } = useTable({
    resource: `orders?shop_id=${id}`,
  });

  return (
    <List headerButtons={<></>}>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex={["order_at"]}
          title="วันที่สั่งซื้อ"
          render={(value: any) => (
            <DateField value={value} format="D/MM/BBBB" />
          )}
        />
        <Table.Column dataIndex={["customer_name"]} title="ชื่อผู้สั่ง" />
        <Table.Column dataIndex={["customer_email"]} title="อีเมล์" />
        <Table.Column dataIndex={["customer_phone"]} title="เบอร์โทร" />
        <Table.Column
          dataIndex={["order_items"]}
          title="จำนวนรายการ"
          render={(item: any) => item?.length}
        />
        <Table.Column
          title={translate("table.actions")}
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton
                hideText
                size="small"
                recordItemId={record.id}
                onClick={() => push(`show/${record.id}`)}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
