import React, { useState } from "react";
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
  useParsed,
  useCustomMutation,
} from "@refinedev/core";
import { Show, TagField, TextField } from "@refinedev/antd";
import { Typography, Switch, Image } from "antd";
import { API_URL } from "config/axios";
import ImageField from "components/Image";

const { Title } = Typography;

interface RecordType {
  id: number;
  fullname: string;
  email: string;
  phone: string;
  role: string;
  username: string;
  status: string;
  profile: {
    asset: string;
  };
}

export const UsersShow: React.FC<IResourceComponentsProps> = () => {
  const { id, resource } = useParsed();
  const { queryResult } = useShow<RecordType>({ resource: "users", id });
  const { data, isLoading, refetch } = queryResult;
  const { mutate } = useCustomMutation();
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);

  const record = data?.data;

  const updateStatus = (value: any) => {
    setUpdateStatusLoading(true);
    mutate(
      {
        url: `/users/update_status/${id}`,
        method: "put",
        values: {
          status: value ? "approved" : "banned",
        },
      },
      {
        onError: (error, variables, context) => {
          setUpdateStatusLoading(false);
        },
        onSuccess: (data, variables, context) => {
          setUpdateStatusLoading(false);
          refetch();
        },
      }
    );
  };

  return (
    <Show title={`แสดง${resource?.meta?.label}`} isLoading={isLoading}>
      <Title level={5}>โปรไฟล์</Title>
      <ImageField value={record?.profile?.asset} />
      <Title level={5}>ชื่อ</Title>
      <TextField value={record?.fullname} />
      <Title level={5}>อีเมล์</Title>
      <TextField value={record?.email} />
      <Title level={5}>เบอร์โทร</Title>
      <TextField value={record?.phone} />
      <Title level={5}>ตำแหน่ง</Title>
      <TextField value={record?.role} />
    </Show>
  );
};
