import React, { useState } from "react";
import { Card } from "antd";
import { Column } from "@ant-design/charts";
import { useList } from "@refinedev/core";
import { SelectDate } from "./SelectDate";

function getThaiMonth(month: number) {
  const thaiMonths = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];
  return thaiMonths[month - 1];
}

const CustomerTotalCard: React.FC = () => {
  const [dateString, setDateString] = useState("");

  const { data } = useList({
    resource: `/analytics/customer_graph?${dateString}`,
    queryOptions: {
      select: (data: any) => data.data,
    },
  });

  const config = {
    data,
    xField: "label",
    yField: "value",
    legend: false,
    meta: {
      value: {
        alias: "จำนวน",
      },
    },
  };

  return (
    <Card bordered={false} title="จำนวนนักท่องเที่ยว">
      <SelectDate value={dateString} onChange={setDateString} />
      {/* @ts-ignore */}

      {data ? <Column {...config} /> : "กำลังโหลดข้อมูล"}
    </Card>
  );
};

export default CustomerTotalCard;
