import React, { useState, useEffect } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload, Form } from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { API_URL } from "config/axios";

interface props {
  label: string;
  name: any;
  value?: any;
  rules?: any;
}

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const UploadOne: React.FC<props> = (props) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    if (props?.value) {
      const { value } = props;
      setImageUrl(`${API_URL}${value?.asset}`);
    }
  }, [props.value]);

  return (
    <>
      <Form.Item
        label={props.label}
        name={props.name}
        rules={props.rules}
        getValueFromEvent={(e: any) => e.file.response}
      >
        <Upload
          name="asset"
          listType="picture-card"
          className="avatar-uploader upload-one"
          showUploadList={false}
          action={`${API_URL}/attachments`}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          headers={{
            Authorization: `Bearer ${sessionStorage.getItem("refine-auth")}`,
          }}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              style={{
                width: "100%",
                height: 90,
                borderRadius: 6,
                objectFit: "cover",
                margin: "0 5px",
              }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </Form.Item>
    </>
  );
};

export default UploadOne;
