import React from "react";
import {
  IResourceComponentsProps,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Input, DatePicker } from "antd";
import dayjs from "dayjs";
import UploadOne from "components/UploadOne";

export const CustomerEdit: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm();
  const { mutate } = useUpdate();

  const Data = queryResult?.data?.data;

  const handleUpdate = (values: any) => {
    const payload = {
      customer: {
        ...values,
      },
    };

    mutate({
      resource: "customers",
      values: payload,
      id: values.id,
    });
  };

  return (
    <Edit saveButtonProps={saveButtonProps} title="แก้ไขนักท่องเที่ยว">
      <Form {...formProps} layout="vertical" onFinish={handleUpdate}>
        <UploadOne
          label="โปรไฟล์"
          name={["profile"]}
          value={Data?.profile}
          rules={[
            {
              required: false,
            },
          ]}
        />
        <Form.Item
          label="รหัส"
          name={["id"]}
          rules={[
            {
              required: false,
            },
          ]}
          hidden
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="คำนำหน้า"
          name={["name_title"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ชื่อ"
          name={["first_name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="นามสกุล"
          name={["last_name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="เบอร์โทร"
          name={["phone"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ที่อยู่"
          name={["address"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="รหัสไปรษณีย์"
          name={["post_code"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="บัญชีเข้าใช้งาน"
          name={["username"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="รหัสผ่าน"
          name={["password"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input type="password" />
        </Form.Item>
      </Form>
    </Edit>
  );
};
