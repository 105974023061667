import React, { useState, useEffect } from "react";
import {
  IResourceComponentsProps,
  useForm,
  useShow,
  useNavigation,
} from "@refinedev/core";
import { Show, DateField, ImageField } from "@refinedev/antd";
import { Button, Descriptions, Popconfirm, Select, Space } from "antd";
import axiosInstance, { API_URL } from "config/axios";

import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { MyDocument } from "./PdfComponent";
import { GuideProps } from "types";
import { FilePdfOutlined } from "@ant-design/icons";
import { fallbackImage } from "utils";

const ORG_ID = localStorage.getItem("org_id");

export const LocalGuideShow: React.FC<IResourceComponentsProps> = () => {
  const { goBack } = useNavigation();
  const { queryResult } = useShow();
  const { onFinish } = useForm({
    action: "edit",
  });

  const [status, setStatus] = useState("");

  const { data, isLoading } = queryResult;

  const record = data?.data as GuideProps;

  const fileRegex = /\/([^/]+)$/;

  const updateStatus = async () => {
    const res = await axiosInstance.put(
      `/${ORG_ID}/local_guides/update_status/${record?.id}`,
      {
        status,
      }
    );
    if (res.status === 200) {
      goBack();
    }
  };

  useEffect(() => {
    setStatus(record?.status);
  }, [record]);

  return (
    <Show
      title="แสดงไกด์ท้องถิ่น"
      isLoading={isLoading}
      headerButtons={() => (
        <Space>
          <span style={{ fontWeight: "600", fontSize: 18 }}>สถานะ : </span>
          <Select
            value={status}
            style={{ width: 200 }}
            onChange={setStatus}
            options={[
              { value: "pending", label: "กำลังดำเนินการ" },
              { value: "approved", label: "อนุมัติ" },
              { value: "cancelled", label: "ไม่อนุมัติ" },
              { value: "banned", label: "แบน" },
            ]}
          />
          <Popconfirm
            title="คุณต้องการยืนยันหรือไม่"
            okText="ใช่"
            cancelText="ไม่ใช่"
            onConfirm={updateStatus}
          >
            <Button type="primary" disabled={status === ""}>
              ยืนยัน
            </Button>
          </Popconfirm>
          <PDFDownloadLink
            document={<MyDocument data={record} />}
            fileName={"application.pdf"}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <Button type="primary" icon={<FilePdfOutlined />} loading>
                  ดาวน์โหลด
                </Button>
              ) : (
                <Button type="primary" icon={<FilePdfOutlined />}>
                  ดาวน์โหลด
                </Button>
              )
            }
          </PDFDownloadLink>
        </Space>
      )}
    >
      <Descriptions title="ข้อมูลไกด์ท้องถิ่น" layout="vertical">
        <Descriptions.Item label="โปรไฟล์" span={3}>
          <ImageField
            value={API_URL + record?.profile?.asset}
            style={{ width: 150 }}
            fallback={fallbackImage}
          />
        </Descriptions.Item>
        <Descriptions.Item label="ชื่อไกด์ท้องถิ่น">
          {record?.name}
        </Descriptions.Item>
        <Descriptions.Item label="ที่อยู่">{record?.address}</Descriptions.Item>
        <Descriptions.Item label="ชุมชน">
          {record?.community?.name}
        </Descriptions.Item>
        <Descriptions.Item label="ประสบการณ์">
          {record?.experience}
        </Descriptions.Item>
        <Descriptions.Item label="เฟสบุ๊ค">
          {record?.facebook}
        </Descriptions.Item>
        <Descriptions.Item label="เบอร์โทร" span={2}>
          {record?.phone}
        </Descriptions.Item>
        <Descriptions.Item label="รายละเอียด" span={3}>
          <div dangerouslySetInnerHTML={{ __html: record?.detail }} />
        </Descriptions.Item>
        <Descriptions.Item label="วันที่ลงทะเบียน" span={3}>
          <DateField value={record?.created_at} />
        </Descriptions.Item>
        <Descriptions.Item label="สำเนาบัตรประชาชน">
          <a
            href={API_URL + record?.id_card_pdf?.asset}
            target="_blank"
            rel="noopener noreferrer"
          >
            {record?.id_card_pdf?.asset.match(fileRegex)[1]}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label="สำเนาทะเบียนบ้าน">
          <a
            href={API_URL + record?.house_registration_pdf?.asset}
            target="_blank"
            rel="noopener noreferrer"
          >
            {record?.house_registration_pdf?.asset.match(fileRegex)[1]}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label="เอกสารการจดทะเบียนร้านค้า/พาณิชย์">
          <a
            href={API_URL + record?.commercial_registration_pdf?.asset}
            target="_blank"
            rel="noopener noreferrer"
          >
            {record?.certificate_pdf?.asset.match(fileRegex)[1]}
          </a>
        </Descriptions.Item>
      </Descriptions>
    </Show>
  );
};
