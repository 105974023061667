import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useExport,
} from "@refinedev/core";
import {
  useTable,
  List,
  ShowButton,
  DateField,
  ExportButton,
  EditButton,
  DeleteButton,
} from "@refinedev/antd";
import { Table, Space, Tag } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import dayjs from "dayjs";
import SelfRegisterButton from "components/SelfRegisterButton";

const ORG_ID = localStorage.getItem("org_id");

interface ILocalGuide {
  id: number;
  name: string;
  experience: string;
  content: string;
  community: {
    name: string;
  };
  status: string;
  created_at: string;
}

export const LocalGuideList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    resource: `${ORG_ID}/local_guides`,
  });

  const { triggerExport, isLoading: exportLoading } = useExport<ILocalGuide>({
    mapData: (item) => {
      return {
        รหัส: item.id,
        ชื่อไกด์ท้องถิ่น: item.name,
        ประสบการณ์: item.experience,
        ชุมชน: item.community.name,
        สถานะ: status.find((i) => i.value === item.status)?.label,
        วันที่ลงทะเบียน: dayjs(item.created_at).format("D/MM/YYYY"),
      };
    },
    exportOptions: {
      filename: "localgudes",
    },
  });

  const status = [
    {
      label: "รอดำเนินการ",
      value: "pending",
      type: "processing",
      icon: <SyncOutlined spin />,
    },
    {
      label: "ไม่ตอบรับ",
      value: "cancelled",
      type: "error",
      icon: <CloseCircleOutlined />,
    },
    {
      label: "แบน",
      value: "banned",
      type: "error",
      icon: <MinusCircleOutlined />,
    },
    {
      label: "ตอบรับ",
      value: "approved",
      type: "success",
      icon: <CheckCircleOutlined />,
    },
  ];

  return (
    <List
      canCreate={false}
      headerButtons={
        <Space>
          <SelfRegisterButton to="https://localguide.admin.aowtani.com/register" />
          <ExportButton onClick={triggerExport} loading={exportLoading} />
        </Space>
      }
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="รหัส" />
        <Table.Column dataIndex="name" title="ชื่อไกด์ท้องถิ่น" />
        <Table.Column dataIndex="experience" title="ประสบการณ์" />
        <Table.Column dataIndex={["community", "name"]} title="ชุมชน" />
        <Table.Column
          dataIndex="status"
          title="สถานะ"
          render={(value) => {
            const find = status.find((i) => i.value === value);
            return (
              <Tag icon={find?.icon} color={find?.type}>
                {find?.label}
              </Tag>
            );
          }}
        />
        <Table.Column
          dataIndex="created_at"
          title="วันที่ลงทะเบียน"
          render={(text) => <DateField value={text} format="D/MM/YYYY" />}
        />
        <Table.Column
          title="จัดการ"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
