import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  UrlField,
  TagField,
  DateField,
  DeleteButton,
  ImageField,
} from "@refinedev/antd";
import { Table, Space, Tag } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { API_URL } from "config/axios";
import { fallbackImage } from "utils";
import SelfRegisterButton from "components/SelfRegisterButton";

export const OrganizationsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const status = [
    {
      label: "กำลังตรวจสอบ",
      value: "pending",
      type: "processing",
      icon: <SyncOutlined spin />,
    },
    {
      label: "ไม่ตอบรับ",
      value: "cancelled",
      type: "error",
      icon: <CloseCircleOutlined />,
    },
    {
      label: "แบน",
      value: "banned",
      type: "error",
      icon: <MinusCircleOutlined />,
    },
    {
      label: "ตอบรับ",
      value: "approved",
      type: "success",
      icon: <CheckCircleOutlined />,
    },
  ];

  return (
    <List
      headerButtons={
        <SelfRegisterButton to="https://organization.admin.aowtani.com/" />
      }
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="รหัส" />
        <Table.Column
          dataIndex={["logo", "asset"]}
          title="โลโก้"
          render={(value) => (
            <ImageField
              value={API_URL + value}
              fallback={fallbackImage}
              style={{ borderRadius: "100px" }}
            />
          )}
        />
        <Table.Column dataIndex="name" title="ชื่อองค์กร" />
        <Table.Column dataIndex="address" title="ที่อยู่" />
        <Table.Column dataIndex="detail" title="รายละเอียด" />
        <Table.Column dataIndex="president_name" title="นายก" />
        <Table.Column
          dataIndex={["website"]}
          title="เว็บไซต์"
          render={(value: any) => <UrlField value={value} />}
        />
        <Table.Column dataIndex="phone" title="เบอร์โทร" />
        <Table.Column
          dataIndex="status"
          title="สถานะ"
          render={(value) => {
            const find = status.find((i) => i.value === value);
            return (
              <Tag icon={find?.icon} color={find?.type}>
                {find?.label}
              </Tag>
            );
          }}
        />
        <Table.Column
          title={translate("table.actions")}
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
