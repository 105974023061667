import React, { useEffect } from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DateField,
  DeleteButton,
} from "@refinedev/antd";
import { Table, Space, Tag, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";

const currentUser = localStorage.getItem("username");

export const MastersList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable();

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="รหัส" />
        <Table.Column
          dataIndex="username"
          title="บัญชีเข้าใช้งาน"
          // render={(text) =>
          //   text === currentUser ? (
          //     <Tooltip title="คุณกำลังใช้งานด้วยบัญชีนี้">
          //       <Tag icon={<UserOutlined />} color="success">
          //         {text}
          //       </Tag>
          //     </Tooltip>
          //   ) : (
          //     text
          //   )
          // }
        />
        <Table.Column dataIndex="fullname" title="ชื่อ" />
        <Table.Column dataIndex="phone" title="เบอร์โทร" />
        <Table.Column
          dataIndex={["created_at"]}
          title="สร้างเมื่อ"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          title="จัดการ"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              {/* <ShowButton hideText size="small" recordItemId={record.id} /> */}
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
