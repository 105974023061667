import React from "react";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import type { TimeRangePickerProps } from "antd";
import { DatePicker, Space } from "antd";

import locale from "antd/es/date-picker/locale/th_TH";

import "dayjs/locale/th";

const { RangePicker } = DatePicker;

export const SelectDate: React.FC<{ value?: string; onChange: any }> = ({
  value,
  onChange,
}) => {
  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      const dateString = `date_from=${dateStrings[0]}&date_to=${dateStrings[1]}`;
      onChange(dateString);
    } else {
      console.log("Clear");
    }
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    {
      label: "วันนี้",
      value: [dayjs(), dayjs()],
    },
    {
      label: "เดือนปัจจุบัน",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "เดือนที่แล้ว",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "2 เดือน",
      value: [
        dayjs().subtract(2, "month").startOf("month"),
        dayjs().startOf("month"),
      ],
    },
    {
      label: "3 เดือน",
      value: [
        dayjs().subtract(3, "month").startOf("month"),
        dayjs().startOf("month"),
      ],
    },
    {
      label: "6 เดือน",
      value: [
        dayjs().subtract(6, "month").startOf("month"),
        dayjs().startOf("month"),
      ],
    },
    {
      label: "1 ปี",
      value: [
        dayjs().subtract(1, "year").startOf("month"),
        dayjs().startOf("month"),
      ],
    },
    {
      label: "ปีปัจจุบัน",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "ปีก่อน",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
    {
      label: "5 ปีที่แล้ว",
      value: [dayjs().subtract(4, "year").startOf("year"), dayjs()],
    },
  ];

  return (
    <>
      <RangePicker
        locale={locale}
        presets={rangePresets}
        onChange={onRangeChange}
        defaultValue={[dayjs().startOf("month"), dayjs().endOf("month")]}
      />
      <br />
      <br />
    </>
  );
};
