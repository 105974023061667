import React, { useState, useEffect } from "react";
import {
  IResourceComponentsProps,
  useForm,
  useShow,
  useNavigation,
  useParsed,
  useUpdate,
  useCustomMutation,
  useResource,
} from "@refinedev/core";
import { Show, DateField, ImageField } from "@refinedev/antd";
import { Button, Descriptions, Popconfirm, Select, Space } from "antd";
import axiosInstance, { API_URL } from "config/axios";
import { NoImage } from "utils";
import ReviewPage from "components/ReviewPage";
import ImageList from "components/ImageList";

export const PartnerShow: React.FC<IResourceComponentsProps> = () => {
  const { goBack, push, replace, listUrl } = useNavigation();
  const { id, resource } = useParsed();
  const { queryResult } = useShow({ resource: "business_partners", id });

  const [isLoading, setIsLoading] = useState(true);

  const { mutate } = useCustomMutation();

  const [status, setStatus] = useState("");

  const { data, refetch } = queryResult;

  const record = data?.data;

  const fileRegex = /\/([^/]+)$/;

  const { identifier } = useResource();

  console.log({ identifier });

  const updateStatus = async () => {
    mutate(
      {
        url: `/business_partners/update_status/${record?.id}`,
        method: "put",
        values: {
          status,
        },
      },
      {
        onSuccess: () => {
          goBack();
        },
      }
    );
  };

  useEffect(() => {
    setStatus(record?.status);
  }, [record]);

  const types = [
    {
      label: "โรงแรม",
      value: "Hotel",
    },
    {
      label: "ร้านอาหาร",
      value: "Restaurant",
    },
    {
      label: "ทัวร์",
      value: "TourActivity",
    },
    {
      label: "ร้านค้า",
      value: "Shop",
    },
  ];

  useEffect(() => {
    setIsLoading(false);
  }, [data]);

  return (
    <>
      <Show
        isLoading={isLoading}
        title={`แสดง${resource?.meta?.label}`}
        headerButtons={() => (
          <Space>
            <span style={{ fontWeight: "600", fontSize: 18 }}>สถานะ : </span>
            <Select
              value={status}
              style={{ width: 200 }}
              onChange={setStatus}
              options={[
                { value: "pending", label: "กำลังดำเนินการ" },
                { value: "approved", label: "อนุมัติ" },
                { value: "cancelled", label: "ไม่อนุมัติ" },
                { value: "banned", label: "แบน" },
              ]}
            />
            <Popconfirm
              title="คุณต้องการยืนยันหรือไม่"
              okText="ใช่"
              cancelText="ไม่ใช่"
              onConfirm={updateStatus}
            >
              <Button type="primary" disabled={status === ""}>
                ยืนยัน
              </Button>
            </Popconfirm>
          </Space>
        )}
      >
        <Descriptions title="ข้อมูลธุรกิจ" layout="vertical">
          <Descriptions.Item label="โลโก้" span={3}>
            {record?.logo ? (
              <ImageField
                value={API_URL + record?.logo?.asset}
                style={{ width: 150 }}
              />
            ) : (
              <NoImage />
            )}
          </Descriptions.Item>
          <Descriptions.Item label="ชื่อธุรกิจ">
            {record?.name}
          </Descriptions.Item>
          <Descriptions.Item label="ประเภท">
            {types.find((i) => i.value === record?.type)?.label}{" "}
            {record?.type_details && `(${record.type_details})`}
          </Descriptions.Item>
          <Descriptions.Item label="ที่อยู่">
            {record?.address}
          </Descriptions.Item>
          <Descriptions.Item label="ชุมชน">
            {record?.community.name}
          </Descriptions.Item>
          <Descriptions.Item label="เฟสบุ๊ค">
            {record?.facebook}
          </Descriptions.Item>
          <Descriptions.Item label="เบอร์โทร" span={2}>
            {record?.phone}
          </Descriptions.Item>
          <Descriptions.Item label="รูปภาพ" span={3}>
            <ImageList images={record?.images} />
          </Descriptions.Item>
          <Descriptions.Item label="รายละเอียด" span={3}>
            {record?.detail}
          </Descriptions.Item>
          <Descriptions.Item label="วันที่ลงทะเบียน" span={3}>
            <DateField value={record?.created_at} />
          </Descriptions.Item>
          <Descriptions.Item label="สำเนาบัตรประชาชน">
            <a
              href={API_URL + record?.id_card_pdf?.asset}
              target="_blank"
              rel="noopener noreferrer"
            >
              {record?.id_card_pdf?.asset.match(fileRegex)[1]}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="สำเนาทะเบียนบ้าน">
            <a
              href={API_URL + record?.house_registration_pdf?.asset}
              target="_blank"
              rel="noopener noreferrer"
            >
              {record?.house_registration_pdf?.asset.match(fileRegex)[1]}
            </a>
          </Descriptions.Item>
          <Descriptions.Item label="เอกสารการจดทะเบียนร้านค้า/พาณิชย์">
            <a
              href={API_URL + record?.commercial_registration_pdf?.asset}
              target="_blank"
              rel="noopener noreferrer"
            >
              {record?.commercial_registration_pdf?.asset.match(fileRegex)[1]}
            </a>
          </Descriptions.Item>
        </Descriptions>
      </Show>
      <ReviewPage resource="business_partners" />
    </>
  );
};
