import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useParsed,
  useNavigation,
} from "@refinedev/core";
import { useTable, List, ShowButton, DateField } from "@refinedev/antd";
import { Table, Space, Tag } from "antd";

export const BookingList: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParsed();
  const { push } = useNavigation();

  const { tableProps } = useTable({
    resource: `bookings`,
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex={["created_at"]}
          title="วันที่จอง"
          render={(value: any) => (
            <DateField value={value} locales="th" format="D/MM/BBBB" />
          )}
        />
        <Table.Column dataIndex="id" title="รหัส" />
        <Table.Column dataIndex="customer_name" title="ชื่อผู้จอง" />
        <Table.Column dataIndex={["package", "name"]} title="ชื่อแพ็คเกจ" />
        <Table.Column dataIndex="price" title="ราคา" />
        <Table.Column
          title="จัดการ"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton
                hideText
                size="small"
                recordItemId={record.id}
                onClick={() => push(`show/${record.id}`)}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
