import React, { useState } from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useResource,
  useNavigation,
  useCustomMutation,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DateField,
  DeleteButton,
} from "@refinedev/antd";
import { Table, Space, Tag, Button, Switch } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

export const UsersList: React.FC<IResourceComponentsProps> = () => {
  const { push } = useNavigation();
  const { identifier } = useResource();
  let searchString = "type=";
  let userType = identifier?.substring(
    identifier?.indexOf(searchString) + searchString.length
  );

  const {
    tableProps,
    tableQueryResult: { refetch, data },
  } = useTable({
    syncWithLocation: true,
    queryOptions: {
      select: (data) => {
        let d: any = data.data.filter(
          (item: any) => item.permission?.app_able_type === userType
        );
        data.data = d;
        return data;
      },
    },
  });

  const { mutate } = useCustomMutation();
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);

  const app = [
    {
      name: "BusinessPartner",
      label: "พาร์ทเนอร์",
      url: "https://partners.admin.aowtani.com/register",
    },
    {
      name: "LocalGuide",
      label: "ไกด์ท้องถิ่น",
      url: "https://localguide.admin.aowtani.com/register",
    },
    {
      name: "Organization",
      label: "องค์กร",
      url: "https://organization.admin.aowtani.com/register",
    },
  ];

  const status = [
    {
      label: "รอดำเนินการ",
      value: "pending",
      type: "processing",
      icon: <SyncOutlined spin />,
    },
    {
      label: "ไม่ตอบรับ",
      value: "cancelled",
      type: "error",
      icon: <CloseCircleOutlined />,
    },
    {
      label: "ถูกปิดการใช้งาน",
      value: "banned",
      type: "error",
      icon: <MinusCircleOutlined />,
    },
    {
      label: "ใช้งานอยู่",
      value: "approved",
      type: "success",
      icon: <CheckCircleOutlined />,
    },
  ];

  const updateStatus = (value: any, id: any) => {
    setUpdateStatusLoading(true);
    mutate(
      {
        url: `/users/update_status/${id}`,
        method: "put",
        values: {
          status: value ? "approved" : "banned",
        },
      },
      {
        onError: (error, variables, context) => {
          setUpdateStatusLoading(false);
        },
        onSuccess: (data, variables, context) => {
          setUpdateStatusLoading(false);
          refetch();
        },
      }
    );
  };

  console.log({ userType });

  return (
    <List
      headerButtons={
        <Button type="primary" icon={<PlusCircleOutlined />}>
          <a
            href={app.find((item: any) => item.name === userType)?.url}
            target="_blank"
          >
            ลงทะเบียนแทน
          </a>
        </Button>
      }
    >
      <Table
        {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          total: tableProps.dataSource?.length,
        }}
      >
        <Table.Column dataIndex="id" title="รหัส" />
        {userType !== "LocalGuide" && (
          <>
            <Table.Column dataIndex="fullname" title="ชื่อ" />
            <Table.Column dataIndex="role" title="ตำแหน่งงาน" />
            <Table.Column dataIndex="email" title="อีเมล์" />
            <Table.Column dataIndex="phone" title="เบอร์โทร" />
          </>
        )}
        <Table.Column dataIndex="username" title="บัญชีเข้าใช้งาน" />
        <Table.Column
          dataIndex="status"
          title="สถานะบัญชี"
          render={(value) => {
            const find = status.find((i) => i.value === value);
            return (
              <Tag icon={find?.icon} color={find?.type}>
                {find?.label}
              </Tag>
            );
          }}
        />

        <Table.Column
          title="จัดการบัญชี"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Switch
              checkedChildren="แบน"
              unCheckedChildren="เปิด"
              onChange={(val) => updateStatus(val, record.id)}
              loading={updateStatusLoading}
              checked={record?.status === "approved"}
            />
          )}
        />

        <Table.Column
          dataIndex={["created_at"]}
          title="สร้างเมื่อ"
          render={(value: any) => <DateField value={value} />}
        />

        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              {userType !== "LocalGuide" && (
                <ShowButton hideText size="small" recordItemId={record.id} />
              )}
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
                resource="users"
                onSuccess={() => {
                  refetch();
                }}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
