import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Input, DatePicker } from "antd";
import dayjs from "dayjs";
import UploadMultiple from "components/UploadMultiple";
import TextEditor from "components/TextEditor";

export const EventsEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm();

  const events = queryResult?.data?.data;

  const { TextArea } = Input;

  const handleFinish = (values: any) => {
    onFinish({
      tani_event: {
        ...values,
      },
    });
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" onFinish={handleFinish}>
        <UploadMultiple
          label="รูปภาพ"
          name={["banners"]}
          value={events?.banners}
        />
        <Form.Item
          label="หัวข้อกิจกรรม"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ที่อยู่"
          name={["address"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="วันที่เริ่ม"
          name={["start_date"]}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="วันที่สิ้นสุด"
          name={["end_date"]}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Embeded Map"
          name={["embed_map"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <TextArea rows={8} />
        </Form.Item>
        <Form.Item
          label="รายละเอียด"
          name={["content"]}
          rules={[{ required: false, message: "Please enter the body" }]}
        >
          {/* @ts-ignore */}
          <TextEditor />
        </Form.Item>
      </Form>
    </Edit>
  );
};
