import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, DatePicker, Space, Typography } from "antd";
import dayjs from "dayjs";
import UploadMultiple from "components/UploadMultiple";
import TextEditor from "components/TextEditor";

export const EventsCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, onFinish } = useForm();

  const { TextArea } = Input;

  const handleFinish = (values: any) => {
    onFinish({
      tani_event: {
        ...values,
      },
    });
  };

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" onFinish={handleFinish}>
        <UploadMultiple label="รูปภาพ" name={["banners"]} />
        <Form.Item
          label="หัวข้อกิจกรรม"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ที่อยู่"
          name={["address"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="วันที่เริ่ม"
          name={["start_date"]}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="วันที่สิ้นสุด"
          name={["end_date"]}
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : undefined,
          })}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label={
            <Space>
              Embed a map
              <Typography.Link
                target="_blank"
                href="https://drive.google.com/file/d/1pTh9pn4q5qxPWD5Ko72U-0HtMf3UgIYa/view"
              >
                ดูวิธีการ
              </Typography.Link>
            </Space>
          }
          name={["embed_map"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <TextArea rows={8} />
        </Form.Item>
        <Form.Item
          label="รายละเอียด"
          name={["content"]}
          rules={[{ required: false, message: "Please enter the body" }]}
        >
          {/* @ts-ignore */}
          <TextEditor />
        </Form.Item>
      </Form>
    </Create>
  );
};
