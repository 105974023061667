import React from "react";

export default function AppIcon() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
      }}
    >
      <img width={30} src="/logo.svg" alt="" />
      <h5
        style={{
          fontWeight: "bold",
          marginTop: 10,
          fontSize: 14,
        }}
      >
        ระบบจัดการข้อมูลสำหรับแอดมิน
      </h5>
    </div>
  );
}
