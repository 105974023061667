import React from "react";
import { IResourceComponentsProps, useShow, useParsed } from "@refinedev/core";
import {
  Show,
  NumberField,
  TagField,
  TextField,
  DateField,
  ImageField,
} from "@refinedev/antd";
import { Descriptions, Typography, Button, Popconfirm } from "antd";
import { API_URL } from "config/axios";

const { Title } = Typography;

export const ShowBooking: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParsed();

  const { queryResult } = useShow({
    resource: `bookings`,
    id,
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={false} title="รายละเอียดการจอง" canEdit={false}>
      <Descriptions title="ข้อมูลผู้จอง" layout="vertical">
        <Descriptions.Item label="ชื่อผู้จอง">
          {record?.customer_name}
        </Descriptions.Item>
        <Descriptions.Item label="อีเมล์">
          {record?.customer_email}
        </Descriptions.Item>
        <Descriptions.Item label="เบอร์โทรศัพท์">
          {record?.customer_phone}
        </Descriptions.Item>
        <Descriptions.Item label="วันเดินทาง">
          <DateField value={record?.tour_date} format="D/MM/BBBB" />
        </Descriptions.Item>
        <Descriptions.Item label="จำนวนท่าน">
          {record?.quantity}
        </Descriptions.Item>
        <Descriptions.Item label="หมายเหตุ">{record?.note}</Descriptions.Item>
      </Descriptions>
      <br />
      <br />
      <br />
      <Descriptions title="ข้อมูลผู้แพคเกจ" layout="vertical">
        <Descriptions.Item label="ไอดีแพ็คเกจ">
          {record?.package?.id}
        </Descriptions.Item>
        <Descriptions.Item label="ชื่อแพ็คเกจ">
          {record?.package?.name}
        </Descriptions.Item>
        <Descriptions.Item label="รูปแบบ">
          {record?.package?.period}
        </Descriptions.Item>
        <Descriptions.Item label="ไกด์ท้องถิ่น">
          {record?.local_guide?.name}
        </Descriptions.Item>
        <Descriptions.Item label="การชำระเงิน (บาท)">
          {record?.price}
        </Descriptions.Item>
        <Descriptions.Item label="หลักฐานการชำระเงิน (สลิป)">
          {record?.slip ? (
            <ImageField value={API_URL + record?.slip?.asset} width={100} />
          ) : (
            "ยังไม่ได้ชำระเงิน"
          )}
        </Descriptions.Item>
      </Descriptions>
    </Show>
  );
};
