import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Input, DatePicker, Row, Col } from "antd";
import dayjs from "dayjs";
import UploadOne from "components/UploadOne";

export const OrganizationsEdit: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult, onFinish } = useForm();

  const organizationsData = queryResult?.data?.data;

  const handlerFinish = (values: any) => {
    let payload = {
      organization: {
        ...values,
      },
    };
    onFinish(payload);
  };

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      headerButtons={() => false}
      title="แก้ไขหน่วยงาน"
    >
      <Form {...formProps} layout="vertical" onFinish={handlerFinish}>
        <Form.Item
          label="ID"
          name={["id"]}
          rules={[
            {
              required: false,
            },
          ]}
          hidden
        >
          <Input readOnly disabled />
        </Form.Item>
        <Form.Item
          label="ชื่อองค์กร"
          name={["name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ที่อยู่องค์กร"
          name={["address"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="รายละเอียดองค์กร"
          name={["detail"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="นายกองค์กร"
          name={["president_name"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="เว็บไซต์องค์กร"
          name={["website"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="เบอร์โทรองค์กร"
          name={["phone"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Row>
          <Col span={4}>
            <UploadOne
              label="โลโก้"
              name={["logo"]}
              value={organizationsData?.logo}
            />
          </Col>
          <Col span={20}>
            <UploadOne
              label="แบนเนอร์"
              name={["banner"]}
              value={organizationsData?.banner}
            />
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
