import { AuthBindings } from "@refinedev/core";
import axiosInstance from "config/axios";

export const TOKEN_KEY = "refine-auth";

export const authProvider: AuthBindings = {
  login: async ({ username, password }) => {
    const response = await axiosInstance.post("/masters/login", {
      username,
      password,
    });

    if (!response.data.error) {
      // localStorage.setItem(TOKEN_KEY, response.data.token);
      sessionStorage.setItem(TOKEN_KEY, response.data.token);
      localStorage.setItem("user_id", response.data.id);
      localStorage.setItem("fullname", response.data.fullname);
      localStorage.setItem("username", response.data.username);

      return {
        success: true,
        redirectTo: "/",
      };
    }

    return {
      success: false,
      error: {
        name: "LoginError",
        message: "Invalid username or password",
      },
    };
  },
  logout: async () => {
    localStorage.clear();
    sessionStorage.clear();
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const token = sessionStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;

      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = sessionStorage.getItem(TOKEN_KEY);
    const fullname = localStorage.getItem("fullname");
    if (token) {
      return {
        id: 1,
        name: fullname?.toUpperCase(),
        avatar: "",
      };
    }
    return null;
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
};
