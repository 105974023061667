import React, { useState } from "react";
import {
  IResourceComponentsProps,
  useCustomMutation,
  useParsed,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Show, NumberField, TextField, ImageField } from "@refinedev/antd";
import { Switch, Typography } from "antd";
import { API_URL } from "config/axios";

const { Title } = Typography;

export const CustomerShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading, refetch } = queryResult;
  const { id } = useParsed();
  const { mutate } = useCustomMutation();
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);

  const record = data?.data;

  const updateStatus = (value: any) => {
    setUpdateStatusLoading(true);
    mutate(
      {
        url: `/customers/update_status/${id}`,
        method: "put",
        values: {
          status: value ? "approved" : "banned",
        },
      },
      {
        onError: (error, variables, context) => {
          setUpdateStatusLoading(false);
        },
        onSuccess: (data, variables, context) => {
          setUpdateStatusLoading(false);
          refetch();
        },
      }
    );
  };

  return (
    <Show
      title="แสดงนักท่องเที่ยว"
      isLoading={isLoading}
      headerButtons={
        <Switch
          checkedChildren="ปิดบัญชี"
          unCheckedChildren="เปิดบัญชี"
          onChange={updateStatus}
          loading={updateStatusLoading}
          checked={record?.status === "approved"}
        />
      }
    >
      <Title level={5}>โปรไฟล์</Title>
      <ImageField
        value={API_URL + record?.profile?.asset}
        width={150}
        height={150}
        style={{ objectFit: "cover", borderRadius: "100%" }}
        fallback="/user-fallback.png"
      />
      <br />
      <br />
      <Title level={5}>คำนำหน้า</Title>
      <TextField value={record?.name_title} />
      <Title level={5}>ชื่อ</Title>
      <TextField value={record?.first_name} />
      <Title level={5}>นามสกุล</Title>
      <TextField value={record?.last_name} />
      <Title level={5}>เบอร์โทรศัพท์</Title>
      <TextField value={record?.phone ?? ""} />
      <Title level={5}>ที่อยู่</Title>
      <TextField value={record?.address} />
      <Title level={5}>รหัสไปรษณีย์</Title>
      <TextField value={record?.post_code ?? ""} />
    </Show>
  );
};
