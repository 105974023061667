import React from "react";
import { IResourceComponentsProps, useShow, useParsed } from "@refinedev/core";
import {
  Show,
  NumberField,
  TagField,
  TextField,
  DateField,
  ImageField,
} from "@refinedev/antd";
import {
  Descriptions,
  Typography,
  Button,
  Popconfirm,
  Image,
  Space,
} from "antd";
import { API_URL } from "config/axios";
import { ImageProps } from "types";
import ReviewPage from "components/ReviewPage";

const { Title } = Typography;

export const ShowProduct: React.FC<IResourceComponentsProps> = () => {
  const { id, params } = useParsed();
  console.log(id);
  const { queryResult } = useShow({
    resource: `${params?.shop_id}/products`,
    id,
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <>
      <Show isLoading={false} title="รายละเอียดการสั่ังซื้อ" canEdit={false}>
        <Descriptions title="ข้อมูลสินค้า" layout="vertical">
          <Descriptions.Item label="ชื่อสินค้า">
            {record?.name}
          </Descriptions.Item>
          <Descriptions.Item label="SKU" span={2}>
            {record?.sku}
          </Descriptions.Item>
          <Descriptions.Item label="รูปภาพ" span={3}>
            <Image.PreviewGroup
              preview={{
                onChange: (current, prev) =>
                  console.log(`current index: ${current}, prev index: ${prev}`),
              }}
            >
              <Space>
                {record?.images.map((img: ImageProps) => (
                  <Image width={80} src={API_URL + img.asset} />
                ))}
              </Space>
            </Image.PreviewGroup>
          </Descriptions.Item>
          <Descriptions.Item label="จำนวนคงคลัง">
            {record?.stock}
          </Descriptions.Item>
          <Descriptions.Item label="ราคา" span={2}>
            {record?.price}
          </Descriptions.Item>
          <Descriptions.Item label="รายละเอียด" span={3}>
            <div dangerouslySetInnerHTML={{ __html: record?.details }} />
          </Descriptions.Item>
        </Descriptions>
      </Show>
      <ReviewPage resource="products" />
    </>
  );
};
