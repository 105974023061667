import React from "react";
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Show, EditButton } from "@refinedev/antd";
import { Typography, Button } from "antd";
import { API_URL } from "config/axios";

const { Title } = Typography;

export const OrganizationsShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show
      isLoading={isLoading}
      headerButtons={({ editButtonProps }) =>
        editButtonProps && <EditButton {...editButtonProps} />
      }
      title="ข้อมูลองค์กร"
    >
      <div style={{ width: "100%", height: 282 }}>
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: 8,
          }}
          src={`${API_URL}${record?.banner?.asset}`}
        />
      </div>
      <div
        style={{
          marginTop: 15,
          display: "flex",
          alignContent: "center",
          gap: 6,
        }}
      >
        {record?.logo?.asset ? (
          <img
            style={{
              width: 32,
              height: 32,
              borderRadius: "50%",
            }}
            src={`${API_URL}${record?.logo?.asset}`}
          />
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 9C13.6569 9 15 7.65685 15 6C15 4.34315 13.6569 3 12 3C10.3431 3 9 4.34315 9 6C9 7.65685 10.3431 9 12 9Z"
              fill="currentColor"
            ></path>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6V18C16 20.2091 14.2091 22 12 22C9.79086 22 8 20.2091 8 18V6Z"
              fill="currentColor"
            ></path>
          </svg>
        )}
        <h3>{record?.name}</h3>
      </div>
      <div
        style={{
          marginTop: 15,
          display: "flex",
          alignContent: "center",
          gap: 6,
          color: "#12B886",
        }}
      >
        <svg
          width="15"
          height="15"
          viewBox="0 0 7 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.5 5.25C3.16848 5.25 2.85054 5.1183 2.61612 4.88388C2.3817 4.64946 2.25 4.33152 2.25 4C2.25 3.66848 2.3817 3.35054 2.61612 3.11612C2.85054 2.8817 3.16848 2.75 3.5 2.75C3.83152 2.75 4.14946 2.8817 4.38388 3.11612C4.6183 3.35054 4.75 3.66848 4.75 4C4.75 4.16415 4.71767 4.3267 4.65485 4.47835C4.59203 4.63001 4.49996 4.76781 4.38388 4.88388C4.26781 4.99996 4.13001 5.09203 3.97835 5.15485C3.8267 5.21767 3.66415 5.25 3.5 5.25ZM3.5 0.5C2.57174 0.5 1.6815 0.868749 1.02513 1.52513C0.368749 2.1815 0 3.07174 0 4C0 6.625 3.5 10.5 3.5 10.5C3.5 10.5 7 6.625 7 4C7 3.07174 6.63125 2.1815 5.97487 1.52513C5.3185 0.868749 4.42826 0.5 3.5 0.5Z"
            fill="#12B886"
          />
        </svg>
        <h5>{record?.address}</h5>
      </div>
      <p>{record?.detail}</p>
      <b>
        {record?.president_name} นายก{record?.name}
      </b>
      <div style={{ display: "flex", gap: 35 }}>
        <div
          style={{
            marginTop: 15,
            display: "flex",
            alignContent: "center",
            gap: 6,
            color: "#12B886",
          }}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5 10C7.98528 10 10 7.98528 10 5.5C10 3.01472 7.98528 1 5.5 1C3.01472 1 1 3.01472 1 5.5C1 7.98528 3.01472 10 5.5 10Z"
              stroke="#12B886"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 5.5H10M7.23077 5.5C7.14576 7.14561 6.53962 8.72155 5.5 10C4.46038 8.72155 3.85425 7.14561 3.76923 5.5C3.85425 3.85439 4.46038 2.27845 5.5 1C6.53962 2.27845 7.14576 3.85439 7.23077 5.5Z"
              stroke="#12B886"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h5>{record?.website}</h5>
        </div>
        <div
          style={{
            marginTop: 15,
            display: "flex",
            alignContent: "center",
            gap: 6,
            color: "#12B886",
          }}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 10 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.17838 0.818871C1.28774 0.709671 1.41905 0.624961 1.56362 0.570353C1.70819 0.515746 1.86271 0.492488 2.01694 0.502121C2.17118 0.511754 2.32161 0.554058 2.45826 0.62623C2.59491 0.698401 2.71466 0.798793 2.80958 0.920751L3.93142 2.36207C4.13704 2.62645 4.20954 2.97084 4.12829 3.29586L3.78643 4.66467C3.76875 4.73557 3.76971 4.80983 3.7892 4.88025C3.80869 4.95067 3.84606 5.01485 3.89767 5.06657L5.43325 6.60226C5.48502 6.65398 5.5493 6.69142 5.61984 6.71091C5.69037 6.73041 5.76475 6.73131 5.83574 6.71352L7.20382 6.37163C7.3642 6.33152 7.53159 6.32841 7.69335 6.36252C7.85511 6.39663 8.007 6.46706 8.13754 6.56851L9.57874 7.68981C10.0969 8.09295 10.1444 8.85861 9.68062 9.32176L9.03439 9.96804C8.5719 10.4306 7.88067 10.6337 7.23632 10.4068C5.58711 9.82649 4.08971 8.88226 2.8552 7.64418C1.6173 6.40976 0.673153 4.91247 0.0927914 3.26336C-0.133451 2.61958 0.0696672 1.92767 0.532153 1.46515L1.17838 0.818871Z"
              fill="#12B886"
            />
          </svg>

          <h5>{record?.phone}</h5>
        </div>
      </div>
    </Show>
  );
};
