import React from "react";
import { Card, Tabs } from "antd";
import type { TabsProps } from "antd";
import { Product } from "./Product";
import { Package } from "./Package";

const onChange = (key: string) => {
  console.log(key);
};

const items = [
  {
    key: "1",
    label: "5 สินค้ายอดฮิต",
    children: <Product />,
  },
  {
    key: "2",
    label: "5 ทัวร์ท่องเที่ยวยอดฮิต",
    children: <Package />,
  },
];

const Poppular: React.FC = () => (
  <Card bordered={false} style={{ height: "100%" }} tabList={items} />
);

export default Poppular;
