import React from "react";
import {
  IResourceComponentsProps,
  useParsed,
  useResource,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Input } from "antd";
import UploadOne from "components/UploadOne";

export const UsersEdit: React.FC<IResourceComponentsProps> = () => {
  const { identifier } = useResource();
  let searchString = "type=";

  const { id, resource } = useParsed();
  const { formProps, saveButtonProps, queryResult } = useForm({
    resource: "users",
    id: id,
  });
  const { mutate } = useUpdate();

  const data = queryResult?.data?.data;

  const handleUpdate = (values: any) => {
    const payload = {
      user: {
        ...values,
      },
    };

    mutate({
      resource: "users",
      values: payload,
      id: values.id,
    });
  };

  let userType = identifier?.substring(
    identifier?.indexOf(searchString) + searchString.length
  );

  console.log({ userType });

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      title={`แก้ไข${resource?.meta?.label}`}
    >
      <Form {...formProps} layout="vertical" onFinish={handleUpdate}>
        {userType !== "LocalGuide" && (
          <>
            <UploadOne
              label="โปรไฟล์"
              name={["profile"]}
              value={data?.profile}
              rules={[
                {
                  required: false,
                },
              ]}
            />
            <Form.Item
              label="รหัส"
              name={["id"]}
              rules={[
                {
                  required: true,
                },
              ]}
              hidden
            >
              <Input readOnly disabled />
            </Form.Item>
            <Form.Item
              label="ชื่อ"
              name={["fullname"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="อีเมล์"
              name={["email"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="เบอร์โทร"
              name={["phone"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="ตำแหน่ง"
              name={["role"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        )}
        <Form.Item
          label="บัญชีเข้าใช้งาน"
          name={["username"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="รหัสผ่าน"
          name={["password"]}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input type="password" />
        </Form.Item>
      </Form>
    </Edit>
  );
};
