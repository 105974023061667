import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useParsed,
  useNavigation,
} from "@refinedev/core";
import {
  useTable,
  List,
  EditButton,
  DeleteButton,
  ShowButton,
  Breadcrumb,
} from "@refinedev/antd";
import { Table, Space, Tag } from "antd";
import dayjs from "dayjs";
import { MessageOutlined } from "@ant-design/icons";
import { date } from "utils";

export const PackageList: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParsed();
  const { push } = useNavigation();

  const { tableProps } = useTable({
    resource: `${id}/packages`,
  });

  return (
    <List
      canCreate={false}
      breadcrumb={<Breadcrumb showHome={true} />}
      title="แพ็คเกจ"
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="created_at"
          title="วันที่สร้าง"
          render={(text) => date.thaiFormat(text)}
        />
        {/* <Table.Column dataIndex="id" title="รหัส" /> */}
        <Table.Column dataIndex="name" title="ชื่อแพ็คเกจ" />
        <Table.Column dataIndex="period" title="รูปแบบแพ็คเกจ" />
        <Table.Column
          dataIndex="price"
          title="ราคาแพ็จเกจ (บาท)"
          render={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        />
        <Table.Column
          dataIndex={["destination_visit", "name"]}
          title="สถานที่ท่องเที่ยว"
        />
        <Table.Column
          dataIndex={["local_guide", "name"]}
          title="ไกด์ท้องถิ่น"
        />
        <Table.Column
          title="จัดการ"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton
                hideText
                size="small"
                recordItemId={record.id}
                onClick={() => push(`show/${record.id}`)}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
